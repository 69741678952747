import React from "react";
import logo from "./sterilis_logo.png";
import {Image, Box, Text} from "@chakra-ui/core";

function Header() {
  return <>
    <a href="http://sterilissolutions.com/"
       target="_blank"
       rel="noopener noreferrer"
    >
      <Image
        width='280px'
        src={logo}
        alt='Sterilis Solutions Logo'
        paddingTop='20px'
        paddingBottom='20px'
      />
    </a>
    <Box
      fontSize='3xl'
      padding='7px'
      textAlign='center'
    >
      <Text>Webapp Homepage | Click to Navigate</Text>
    </Box>
  </>;
}

export default Header;
