import React from 'react';
import { theme } from "@chakra-ui/core";
import {ThemeProvider, CSSReset, Flex} from "@chakra-ui/core";
import WebappLinks from './WebappLinks'

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    sterilisBlue: "#22b0bf",
    sterilisOrange: "darkorange",
    invalidRed: "#f54e4e",
  },
};

export default function App() {
  return (
    <Flex
    justify='center'
    height='100vh'
  >
      <ThemeProvider theme={customTheme}>
        <CSSReset/>
        <WebappLinks/>
      </ThemeProvider>
    </Flex>
  );
}
