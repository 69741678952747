import React from "react";
import {Button, Flex, Link, Icon} from "@chakra-ui/core";
import Header from './Header'

export default function WebappLinks() {
  return <Flex
    width='90%'
    align='center'
    direction='column'>
    <Header/>

    <Link
      href="https://roi-calculator.sterilissolutions.com/"
      isExternal
      color="sterilisBlue"
      bg="white"
      fontSize="2xl"
      mt='50px'
      mb='25vh'
    >
      Sterilis ROI calculator <Icon name="external-link" mx="2px"/>
    </Link>
    <Link
      href="https://sitesurvey.sterilissolutions.com/"
      isExternal
      color="sterilisBlue"
      bg="white"
      fontSize="2xl"
    >
      Sterilis Site Survey <Icon name="external-link" mx="2px"/>
    </Link>
  </Flex>
}